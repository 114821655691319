body {
	margin: 0;
	font-family: 'Uber Move Text', sans-serif;
}



/* @font-face {
	font-family: 'MyFont';
	src: local('MyFont'), url(./fonts/MyFont.woff) format('woff');
	
} */