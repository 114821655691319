/*
Template Name: Osahan Eat - Online Food Ordering Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
-- Body
-- Nav
-- login/Register
-- Homepage Search Block
-- Homepage
-- Customize Bootstrap
-- Listing
-- Detail
-- Checkout Page
-- My Account
-- Track Order Page
-- Footer
-- Mobile Media
*/
/* Body */
body {
	background: #ffffff;
	font-size: 14px;
}

p {
	font-size: 13px;
	color: #7a7e8a;
}
img {
	image-rendering: auto;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000000;
}
/* Nav */
.osahan-nav {
	background: #fff none repeat scroll 0 0 !important;
	padding: 0;
	z-index: 9;
}
.osahan-nav .nav-link {
	font-weight: 600;
	padding: 28px 0px !important;
	margin: 0 0 0 31px;
}
.dropdown-cart .dropdown-menu {
	padding-top: 0px !important;
}
.nav-osahan-pic {
	width: 32px;
	height: 32px;
	border: 3px solid #fff;
	box-shadow: 0px 0px 3px #ccc;
	position: relative;
	margin: -8px 5px -6px 0;
	vertical-align: text-top;
}
.dropdown-cart-top {
	border-top: 2px solid #ff3008;
	min-width: 340px;
}
.dropdown-cart-top-header {
	min-height: 107px;
}
.dropdown-cart-top-header .img-fluid {
	border: 1px solid #dcdcdc;
	border-radius: 3px;
	float: left;
	height: 59px;
	padding: 3px;
	width: 59px;
}
.dropdown-cart-top-header p.text-secondary {
	font-size: 11px;
	line-height: 24px;
	font-weight: 600;
}
.dropdown-cart-top-header h6 {
	font-size: 14px;
}
.food-item {
	border: 1px solid;
	border-radius: 2px;
	display: inline-block;
	font-size: 6px;
	height: 13px;
	line-height: 12px;
	text-align: center;
	width: 13px;
}
.dropdown-cart-top-body .food-item {
	float: left;
	margin: 4px 10px 0 0;
	padding-left: 1px;
}
/* login/Register */
:root {
	--input-padding-x: 1.5rem;
	--input-padding-y: 0.75rem;
}
.login,
.image {
	min-height: 100vh;
}
.bg-image {
	background-image: url('/img/login-bg.png');
	background-size: cover;
	background-position: center;
}
.login-heading {
	font-weight: 300;
}
.btn-login {
	font-size: 0.9rem;
	letter-spacing: 0.05rem;
	padding: 0.75rem 1rem;
	border-radius: 2rem;
}
.form-label-group {
	position: relative;
	margin-bottom: 1rem;
}
.form-label-group > input,
.form-label-group > label {
	padding: 15px 0;
	border-radius: 0px;
	height: 51px;
	background: transparent !important;
	border: none;
	border-bottom: 1px solid #ced4da;
	box-shadow: none !important;
}
.form-label-group > label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0;
	/* Override default `<label>` margin */
	line-height: 1.5;
	color: #495057;
	cursor: text;
	/* Match the input under the label */
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: all 0.1s ease-in-out;
}
.form-label-group input::-webkit-input-placeholder {
	color: transparent;
}
.form-label-group input:-ms-input-placeholder {
	color: transparent;
}
.form-label-group input::-ms-input-placeholder {
	color: transparent;
}
.form-label-group input::-moz-placeholder {
	color: transparent;
}
.form-label-group input::placeholder {
	color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
	padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
	padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:placeholder-shown) ~ label {
	padding-top: calc(var(--input-padding-y) / 3);
	padding-bottom: calc(var(--input-padding-y) / 3);
	font-size: 12px;
	color: #777;
}
.btn-google {
	color: white;
	background-color: #ea4335;
}
.btn-facebook {
	color: white;
	background-color: #3b5998;
}
/* Homepage Search Block */
.homepage-search-block {
	background-color: #eef0ef;
}
.homepage-search-form .form-control {
	font-size: 15px;
	height: 50px;
	padding-right: 106px;
}
.homepage-search-form .form-group {
	position: relative;
}
.homepage-search-form .locate-me {
	background: #ececec none repeat scroll 0 0;
	color: #666;
	font-size: 12px;
	padding: 7px 10px;
	border-radius: 3px;
	position: absolute;
	right: 14px;
	top: 9px;
}
.homepage-search-form .locate-me:hover {
	background: #666 none repeat scroll 0 0;
	color: #ffffff;
}
.homepage-search-form .btn {
	font-size: 15px;
	font-weight: 600;
	height: 50px;
	padding: 13px 5px;
}
.homepage-search-form .location-dropdown i {
	left: 18px;
	position: absolute;
	top: 20px;
	z-index: 9;
}
.homepage-search-form .select2-selection {
	border-color: #ced4da !important;
	border-radius: 0.25rem !important;
	height: 50px !important;
	outline: none !important;
}
.homepage-search-form .select2-selection__rendered {
	font-size: 15px;
	line-height: 47px !important;
	padding: 0 28px 0 31px !important;
}
.homepage-search-form .select2-selection__arrow {
	right: 9px !important;
	top: 13px !important;
}
.select2-dropdown {
	margin: -2px 0 0 0;
	border: 1px solid #ced4da;
	border-radius: 3px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #ced4da;
}
.select2-search__field {
	outline: none !important;
	border: 1px solid #ced4da;
}
.owl-carousel {
	position: relative;
}
.owl-carousel .owl-dots {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 89%;
	left: 30vw;
	/* .active {
                background-color: white !important;
                height: 10px !important;
                width: 10px !important;
                transition: all 0.3s linear !important;
            } */
	/* .owl-dot {
                background: hsla(0, 0%, 100%, 0.3);
                display: inline-block;
                height: 8px;
                width: 8px;
                margin-right: 30px;
                vertical-align: middle;
                border: none;
                transition: all 0.3s linear;
                span {
                    visibility: hidden;
                }
            } */
}
.owl-theme .owl-nav [class*='owl-'] {
	position: absolute;
	bottom: calc(50% - 20px);
	background: #fff none repeat scroll 0 0 !important;
	border-radius: 30px !important;
	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
	color: #000 !important;
	height: 40px;
	line-height: 40px;
	margin: 0 !important;
	text-align: center;
	text-transform: uppercase;
	width: 40px;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.owl-theme .owl-nav [class*='owl-'] i {
	font-size: 13px !important;
	font-weight: normal !important;
}
.owl-theme .owl-nav .owl-prev {
	margin-left: -16px !important;
}
.owl-theme .owl-nav .owl-next {
	right: 0px;
	margin-right: -16px !important;
}
/* Homepage */
.owl-carousel-four .item {
	padding: 6px 7px;
}
.osahan-category-item {
	background: #fff;
	margin: 5px 2px;
	border-radius: 3px;
	overflow: hidden;
	text-align: center;
	padding: 7px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	border: 1px solid #fff;
}
.osahan-category-item:hover {
	border: 1px solid #ff3008;
}
.osahan-category-item h6 {
	margin: 0px;
	font-size: 13px;
	color: #ff3008;
}
.osahan-category-item p {
	margin: 0px;
	font-size: 12px;
}
.osahan-category-item img {
	width: 40px !important;
	height: 40px;
	object-fit: cover;
	display: inline-block !important;
	border-radius: 50px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	margin: 2px 0 8px 0;
}
.line {
	background-color: #5e5e5e;
	border-radius: 12px;
	display: inline-table;
	height: 4px;
	margin: 0 0 51px;
	width: 50px;
}
/* Customize Bootstrap */
.small,
small {
	font-size: 85%;
	font-weight: 600;
}
.select2,
.select2 span {
	width: 100% !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	left: 96%;
}
.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 3px;
}
.modal-footer .btn {
	font-size: 14px;
	padding: 12px 16px;
}
.btn-success,
.badge-success {
	background-color: #3ecf8e !important;
	border-color: #3ecf8e !important;
}
.btn-outline-success {
	color: #3ecf8e !important;
	border-color: #3ecf8e !important;
}
.btn-outline-success:hover {
	color: #ffffff !important;
	border-color: #3ecf8e !important;
	background-color: #3ecf8e !important;
}
.text-success {
	color: #3ecf8e !important;
}
.border-success {
	border-color: #3ecf8e !important;
}
.btn-danger,
.badge-danger {
	background-color: #f32129 !important;
	border-color: #f32129 !important;
}
.btn-outline-danger {
	color: #f32129 !important;
	border-color: #f32129 !important;
}
.btn-outline-danger:hover {
	color: #ffffff !important;
	border-color: #f32129 !important;
	background-color: #f32129 !important;
}
.text-danger {
	color: #f32129 !important;
}
.border-danger {
	border-color: #f32129 !important;
}
.btn-primary,
.badge-danger {
	background-color: #00bfff !important;
	border-color: #00bfff !important;
}
.btn-outline-primary {
	color: #00bfff !important;
	border-color: #00bfff !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
	color: #ffffff !important;
	border-color: #00bfff !important;
	background-color: #00bfff !important;
}
.text-primary {
	color: #00bfff !important;
}
.border-primary {
	border-color: #00bfff !important;
}
.btn-lg {
	font-size: 15px;
	font-weight: bold;
	padding: 14px 35px;
	letter-spacing: 1px;
}
.modal-content {
	border: none;
	border-radius: 2px;
}
.btn {
	border-radius: 2px;
}
.form-control {
	font-size: 13px;
	border-radius: 2px;
	min-height: 38px;
}
.card {
	border-radius: 2px;
}
.rounded {
	border-radius: 2px !important;
}
.input-group-text {
	border-radius: 2px;
}
.custom-checkbox .custom-control-label::before {
	border-radius: 2px;
}
.nav-pills .nav-link {
	border-radius: 2px;
}
.alert,
.badge,
.dropdown-menu {
	border-radius: 2px;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
	border-radius: 2px;
}
.bg-dark {
	background-color: #000 !important;
}
.pb-5,
.py-5 {
	padding-bottom: 4rem !important;
}
.pt-5,
.py-5 {
	padding-top: 4rem !important;
}
.dropdown-menu {
	font-size: 13px;
}
.dropdown-item {
	border-radius: 0 !important;
	padding: 7px 18px;
	color: rgba(0, 0, 0, 0.5);
	font-weight: 500;
}
.dropdown-item:focus {
	background-color: #ff3008;
	color: #fff;
}
/* Listing */
.list-cate-page {
	margin: -5px 0px 0px 0px;
}
.filters-card-body {
	padding: 18px 0 0 0;
}
.filters-search {
	position: relative;
}
.filters-search i {
	position: absolute;
	left: 12px;
	top: 13px;
}
.filters-search .form-control {
	padding-left: 34px;
}
.custom-checkbox label.custom-control-label,
.custom-radio label.custom-control-label {
	padding-top: 2px;
	cursor: pointer;
}
.filters-card-header a {
	font-weight: 500;
	width: 100%;
	display: block;
}
.filters-card-header a i {
	margin: 2px -2px 0 0;
}
.position-absolute {
	z-index: 1;
}
iframe.position-absolute {
	z-index: 0;
}
.list-card .count-number {
	margin: 4px 0 0 0;
	display: inline-block;
}
.list-card a {
	color: inherit;
}
.list-card a:hover {
	color: #0091bc;
}
.list-card .star {
	right: 8px;
	bottom: 8px;
}
.list-card .star .badge {
	font-size: 11px;
	padding: 5px 5px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .member-plan {
	left: 8px;
	top: 8px;
}
.list-card .member-plan .badge {
	font-size: 11px;
	padding: 5px 5px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .list-card-image {
	position: relative;
}
.list-card .favourite-heart {
	right: 8px;
	top: 8px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .favourite-heart i {
	background: #fff;
	width: 23px;
	height: 23px;
	display: inline-block;
	text-align: center;
	font-size: 15px;
	line-height: 24px;
	border-radius: 50px;
}
/* detail */
.count-number .btn {
	padding: 2px 5px;
	font-size: 12px;
	border-radius: 0px;
}
.restaurant-detailed-header-left {
	text-shadow: 0px 0px 10px #000;
}
.count-number-input {
	width: 25px;
	text-align: center;
	margin: 0 1px;
	/* background: #6c757d; */
	border: none;
	color: #000;
	font-size: 16px;
	height: 24px;
	border-radius: 0px;
	vertical-align: bottom;
}
.detail-num-input {
	width: 24px;
	text-align: center;
	margin: 0 8px;
	background: transparent !important;
	border: none;
	color: #000;
	font-size: 2em;
	height: 24px;
	border-radius: 0px;
	vertical-align: bottom;
}
.generator-bg {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3008+1,fe792b+100 */
	background: #00bfff; /* Old browsers */
	background: -moz-linear-gradient(-45deg, #00bfff 1%, #00bfff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #00bfff 1%, #00bfff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		135deg,
		#00bfff 1%,
		#00bfff 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00BFFF', endColorstr='#fe792b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.restaurant-detailed-banner {
	position: relative;
}
.restaurant-detailed-banner .img-fluid.cover {
	height: 424px;
	object-fit: cover;
}
.restaurant-detailed-header {
	bottom: 0;
	left: 0;
	padding: 65px 0 17px;
	position: absolute;
	right: 0;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}
.homepage-great-deals-carousel .item img {
	border-radius: 2px;
}
.restaurant-detailed-earn-pts .img-fluid.float-left {
	height: 61px;
}
.card-icon-overlap {
	overflow: hidden;
	position: relative;
}
.red-generator-bg {
	background: #ff3371;
	background: -moz-linear-gradient(-45deg, #ff3371 1%, #fe6753 100%);
	background: -webkit-linear-gradient(-45deg, #ff3371 1%, #fe6753 100%);
	background: linear-gradient(135deg, #ff3371 1%, #fe6753 100%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff3371', endColorstr='#fe6753', GradientType=1);
}
.border-btn {
	border: 1px solid #dee0e6;
	border-radius: 2px;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	padding: 8px 15px;
	margin: 0 0 10px 0;
}
.restaurant-detailed-ratings .generator-bg {
	border-radius: 4px;
	display: inline-block;
	font-size: 12px;
	height: 20px;
	margin: 0 4px 0 0;
	padding: 3px 6px;
}
.explore-outlets-search .form-control {
	border-radius: 2px !important;
	font-size: 15px;
	height: 50px;
	padding: 5px 20px;
}
.explore-outlets-search .btn {
	color: #777777;
	font-size: 15px;
	padding: 13px 17px;
	position: absolute;
	z-index: 9;
	right: 0;
	text-decoration: none;
}
.mall-category-item {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	margin: 0 6px;
	overflow: hidden;
	text-align: center;
	padding: 0 0 7px 0;
}
.mall-category-item img {
	height: 89px;
	object-fit: cover;
	width: 100%;
}
.mall-category-item h6 {
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	padding: 10px 10px 3px 10px;
}
.mall-category-item .btn {
	padding: 2px 8px;
	font-size: 12px;
	right: 7px;
	top: 7px;
}
.mall-category-item small {
	background: #ececec;
	color: #f32129;
	padding: 1px 6px;
	border-radius: 2px;
	font-size: 10px;
}
.mall-category-item:hover small {
	background: #f32129;
	color: #ffffff;
}
.address-map {
	border-radius: 2px;
	overflow: hidden;
}
.new-arrivals-card .card-img .new-arrivals {
	bottom: 11px;
	left: 12px;
	position: absolute;
}
.new-arrivals {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	padding: 1px 13px;
	text-transform: uppercase;
}
.new-arrivals-card .card-img {
	position: relative;
}
.total-like-user {
	border: 2px solid #fff;
	height: 34px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	width: 34px;
}
.total-like-user-main a {
	display: inline-block;
	margin: 0 -17px 0 0;
}
.total-like {
	border: 1px solid;
	border-radius: 50px;
	display: inline-block;
	font-weight: 500;
	height: 34px;
	line-height: 33px;
	padding: 0 13px;
	vertical-align: top;
}
.restaurant-detailed-ratings-and-reviews hr {
	margin: 0 -24px;
}
.star-rating {
	cursor: pointer;
}
.graph-star-rating-header .star-rating {
	font-size: 17px;
}
.progress {
	background: #f2f4f8 none repeat scroll 0 0;
	border-radius: 0;
	height: 30px;
}
.rating-list {
	display: inline-flex;
	margin-bottom: 15px;
	width: 100%;
}
.rating-list-left {
	height: 16px;
	line-height: 29px;
	width: 10%;
}
.rating-list-center {
	width: 80%;
}
.rating-list-right {
	line-height: 29px;
	text-align: right;
	width: 10%;
}
.restaurant-slider-pics {
	bottom: 0;
	font-size: 12px;
	left: 0;
	z-index: 999;
	padding: 0 10px;
}
.restaurant-slider-view-all {
	bottom: 15px;
	right: 15px;
	z-index: 999;
}
.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
	border-color: #3868fb;
	color: #3868fb;
}
.offer-dedicated-nav .nav-link {
	border-bottom: 2px solid #fff;
	color: #000000;
	padding: 16px 0;
	font-weight: 600;
}
.offer-dedicated-nav .nav-item {
	margin: 0 37px 0 0;
}
.restaurant-detailed-action-btn {
	margin-top: 12px;
}
.restaurant-detailed-header-right .btn-success {
	border-radius: 3px;
	height: 45px;
	margin: -18px 0 18px;
	min-width: 130px;
	padding: 7px;
}
.text-black {
	color: #000000;
}
.icon-overlap {
	bottom: -23px;
	font-size: 74px;
	opacity: 0.23;
	position: absolute;
	right: -32px;
}
.menu-list img {
	width: 41px;
	height: 41px;
	object-fit: cover;
}
.restaurant-detailed-header-left img {
	width: 88px;
	height: 88px;
	border-radius: 3px;
	object-fit: cover;
	/* box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; */
}
/* Checkout Page */
.addresses-item a.btn.btn-sm {
	min-width: 157px;
}

.osahan-cart-item-profile img {
	width: 40px;
	height: 40px;
	border: 3px solid #fff;
}
.osahan-cart-item-profile p {
	font-size: 10px;
}

.add-card h6{
	text-align: center;
	vertical-align: middle;
	
}

.add-card button{
	display: block;
	margin:20px auto;
	width:50%;
	border-radius: 25px;
}


.oshan-card-2{
	border-radius: 25px;
	padding:0px 10px;
	vertical-align: center;
	margin:auto;
	width:50%;
}

.oshan-card-2 span {
	font-size: 30px;
	vertical-align: middle;
}

.react-icons {
	display: inline-block;
}

.oshan-card-2 i {
	margin:5px 0px;
	font-size: 40px;
	vertical-align: middle;
	color: #6c757d;
}
.osahan-payment .nav-link {
	padding: 10px 20px;
	border-style: none none solid none;
	border-color:rgba(200,200,200,0.5);
	border-width:thin;
	font-weight: 600;
	color: #535665;
}
.osahan-payment .nav-link i {
	width: 28px;
	height: 28px;
	background: #535665;
	display: inline-block;
	text-align: center;
	line-height: 29px;
	font-size: 15px;
	border-radius: 50px;
	margin: 0 7px 0 0px;
	color: #fff;
}
.osahan-payment .nav-link.active {
	background: #f3f7f8;
	color: #282c3f !important;
}
.osahan-payment .nav-link.active i {
	background: #282c3f !important;
}
.osahan-payment .tab-content {
	background: #f3f7f8;
	padding: 20px;
}
.osahan-card i {
	font-size: 50px;
	vertical-align: middle;
	color: #6c757d;
}
.osahan-card {
	margin: 0 0 0 7px;
}
.osahan-payment label {
	font-size: 12px;
	margin: 0 0 3px 0;
	font-weight: 600;
}
/* My Account */
.payments-item img.mr-3 {
	width: 47px;
}
.order-list .btn {
	border-radius: 2px;
	min-width: 121px;
	font-size: 13px;
	padding: 7px 0 7px 0;
}
.osahan-account-page-left .nav-link {
	padding: 18px 20px;
	border: none;
	font-weight: 600;
	color: #535665;
}
.osahan-account-page-left .nav-link i {
	width: 28px;
	height: 28px;
	background: #535665;
	display: inline-block;
	text-align: center;
	line-height: 29px;
	font-size: 15px;
	border-radius: 50px;
	margin: 0 7px 0 0px;
	color: #fff;
}
.osahan-account-page-left .nav-link.active {
	background: #f3f7f8;
	color: #282c3f !important;
}
.osahan-account-page-left .nav-link.active i {
	background: #282c3f !important;
}
.osahan-user-media img {
	width: 90px;
}
.card offer-card h5.card-title {
	border: 2px dotted #000;
}
.card.offer-card h5 {
	border: 1px dotted #daceb7;
	display: inline-table;
	color: #17a2b8;
	margin: 0 0 19px 0;
	font-size: 15px;
	padding: 6px 10px 6px 6px;
	border-radius: 2px;
	background: #fffae6;
	position: relative;
}
.card.offer-card h5 img {
	height: 22px;
	object-fit: cover;
	width: 22px;
	margin: 0 8px 0 0;
	border-radius: 2px;
}
.card.offer-card h5:after {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid #daceb7;
	content: '';
	left: 30px;
	position: absolute;
	bottom: 0;
}
.card.offer-card h5:before {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #daceb7;
	content: '';
	left: 30px;
	position: absolute;
	top: 0;
}
.card.offer-card .card-btn {
	font-size: 13px;
	color: #ff3008;
	vertical-align: top !important;
	border: 0px !important;
}
.card.offer-card .card-btn:hover {
	color: #0056b3;
}
.payments-item .media {
	align-items: center;
}
.payments-item .media img {
	margin: 0 40px 0 11px !important;
}
.reviews-members .media .mr-3 {
	width: 56px;
	height: 56px;
	object-fit: cover;
}
.order-list img.mr-4 {
	width: 70px;
	height: 70px;
	object-fit: cover;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	border-radius: 2px;
}
.osahan-cart-item p.text-gray.float-right {
	margin: 3px 0 0 0;
	font-size: 12px;
}
.osahan-cart-item .food-item {
	vertical-align: bottom;
}
/* Track Order Page */

.osahan-track-order-page {
	min-height: 676px;
}
.osahan-point {
	z-index: 1;
	width: 50%;
	border-radius: 50%;
	padding-bottom: 50%;
	pointer-events: none;
	background-color: rgba(225, 48, 8, 0.2);
	border: 1px solid #ff3008;
}
/* Footer */
.footer {
	background: #edf1f4;
}
.footer h6 {
	font-size: 14px;
	text-transform: uppercase;
}
.footer ul {
	margin: 0;
	padding: 0;
}
.footer ul li {
	line-height: 26px;
	list-style: outside none none;
}
.footer ul li a {
	color: #6c8293;
}
.search-links a {
	color: #666;
	font-size: 12px;
	line-height: 23px;
	margin: 0 2px;
}
.search-links {
	font-size: 11px;
}
footer {
	background-color: #f0f4f7;
}
/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.m-none {
		display: none;
	}
	.homepage-search-title {
		text-align: center;
	}
	.list-cate-page {
		margin: 22px 0 0 0;
	}
	.d-none-m {
		display: none;
	}
	.row.pt-2.pb-2 {
		padding-bottom: 0px !important;
		padding-top: 0px !important;
	}
	.row.pt-2 {
		padding-top: 0px !important;
	}
	.offer-card-horizontal .col-md-4.col-4.p-4 {
		padding: 0 0 0 15px !important;
	}
	.row.mt-4.pt-2 {
		margin-top: 0px !important;
		padding-top: 0px !important;
	}
	.homepage-add-section {
		padding: 0 13px;
	}
	.breadcrumb-osahan h1 {
		font-size: 34px;
	}
	.breadcrumb-osahan h6 {
		font-size: 15px;
	}
	.breadcrumb-osahan {
		padding-left: 15px;
		padding-right: 15px;
	}
	.reviews-members .media .mr-3 {
		width: 36px;
		height: 36px;
	}
	.total-like {
		font-size: 12px;
	}
	.restaurant-detailed-action-btn.float-right .btn {
		margin: 3px 0px;
		font-size: 13px;
	}
	.login .col-md-9.col-lg-8.mx-auto.pl-5.pr-5 {
		padding: 0 11px !important;
	}
	.login.d-flex.align-items-center.py-5 {
		padding: 0 !important;
	}
	.h5,
	h5 {
		font-size: 16px;
	}
	.homepage-add-section .col-md-3.col-6 {
		padding: 2px 2px;
	}
	.homepage-ad .item {
		padding: 0 2px;
	}
	.osahan-nav {
		padding: 15px 15px;
	}
	.navbar-toggler {
		padding: 5px 8px;
		border-radius: 2px;
	}
	.osahan-nav .nav-link {
		margin: 0px;
		text-align: center;
		padding: 14px 0 !important;
		border-bottom: 1px solid #eef0ef;
	}
	.osahan-nav .dropdown-item {
		text-align: center;
	}
	.osahan-slider {
		padding: 18px 0 0 0px !important;
	}
	.pt-5,
	.py-5 {
		padding-top: 3rem !important;
	}
	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important;
	}
	.homepage-search-block {
		padding: 3rem 0 !important;
	}
	.mobile-none {
		display: none;
	}
	.card {
		margin-bottom: 15px !important;
	}
	p {
		font-size: 12px;
	}
	.restaurant-detailed-header-left img {
		float: none !important;
		margin: 0 0 14px 0 !important;
	}
	.restaurant-detailed-header-left {
		text-align: center;
	}
	.restaurant-detailed-header-right .btn-success {
		margin-top: 18px;
	}
	.restaurant-detailed-header-right.text-right {
		text-align: center !important;
	}
	.restaurant-detailed-action-btn.float-right {
		float: none !important;
		margin: auto;
		text-align: center;
		width: 100% !important;
		display: block;
		padding: 12px 0;
		border-bottom: 1px solid #dedede;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.offer-dedicated-nav .nav-item {
		flex: auto;
		border-bottom: 1px solid #ccc;
		padding: 0;
		margin: 0 11px !important;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.address-map.float-right.ml-5 {
		float: none !important;
		margin: 0 0 20px 0 !important;
	}
	.address-map.float-right.ml-5 iframe {
		width: 100%;
	}
	.osahan-track-order-detail p.text-gray.mb-5 {
		margin: 1px 0 11px 0 !important;
	}
	.osahan-account-page-left .nav-tabs {
		padding-left: 0px !important;
	}
	.osahan-account-page-left {
		height: auto !important;
		margin: 0 0 15px 0;
	}
	.order-list .float-right {
		float: none !important;
	}
	.row.mb-4 {
		margin-bottom: 0px !important;
	}
	.app {
		margin-bottom: 26px;
	}
	.footer ul li a {
		font-size: 12px;
	}
	.footer h6 {
		font-size: 13px;
		text-transform: capitalize;
	}
	.osahan-payment .col-sm-4.pr-0 {
		padding-right: 15px !important;
	}
	.osahan-payment .col-sm-8.pl-0 {
		padding-left: 15px !important;
		margin: 14px 0 0 0;
	}
	.p-5.osahan-invoice.bg-white.shadow-sm {
		padding: 15px !important;
	}
	.h3,
	h3 {
		font-size: 22px;
	}
	.p-5 {
		padding: 20px !important;
	}
	.osahan-account-page-right {
		padding: 0px !important;
		border: none;
		background: transparent !important;
		box-shadow: none !important;
		margin-top: 15px;
	}
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
	.homepage-add-section .col-md-3.col-6 {
		padding: 2px 2px;
	}
	.homepage-search-title {
		text-align: center;
	}
	.row.pt-2.pb-2 {
		padding-bottom: 0px !important;
		padding-top: 0px !important;
	}
	.row.pt-2 {
		padding-top: 0px !important;
	}
	.d-none-m {
		display: none;
	}
	.list-cate-page {
		margin: 22px 0 0 0;
	}
	.row.mt-4.pt-2 {
		margin-top: 0px !important;
		padding-top: 0px !important;
	}
	.offer-card-horizontal .col-md-4.col-4.p-4 {
		padding: 0 0 0 15px !important;
	}
	.homepage-add-section {
		padding: 0 13px;
	}
	.homepage-ad .item {
		padding: 0 2px;
	}
	.container {
		max-width: 100%;
	}
	.osahan-nav {
		padding: 15px 15px;
	}
	.navbar-toggler {
		padding: 5px 8px;
		border-radius: 2px;
	}
	.osahan-nav .nav-link {
		margin: 0px;
		text-align: center;
		padding: 14px 0 !important;
		border-bottom: 1px solid #eef0ef;
	}
	.osahan-nav .dropdown-item {
		text-align: center;
	}
	.osahan-slider {
		padding: 18px 0 0 0px !important;
	}
	.pt-5,
	.py-5 {
		padding-top: 3rem !important;
	}
	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important;
	}
	.homepage-search-block {
		padding: 3rem 0 !important;
	}
	.mobile-none {
		display: none;
	}
	.card {
		margin-bottom: 15px !important;
	}
	p {
		font-size: 12px;
	}
	.restaurant-detailed-header-left img {
		float: none !important;
		margin: 0 0 14px 0 !important;
	}
	.restaurant-detailed-header-left {
		text-align: center;
	}
	.restaurant-detailed-header-right .btn-success {
		margin-top: 18px;
	}
	.restaurant-detailed-header-right.text-right {
		text-align: center !important;
	}
	.restaurant-detailed-action-btn.float-right {
		float: none !important;
		margin: auto;
		text-align: center;
		width: 100% !important;
		display: block;
		padding: 12px 0;
		border-bottom: 1px solid #dedede;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.offer-dedicated-nav .nav-item {
		flex: auto;
		border-bottom: 1px solid #ccc;
		padding: 0;
		margin: 0 11px !important;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.address-map.float-right.ml-5 {
		float: none !important;
		margin: 0 0 20px 0 !important;
	}
	.address-map.float-right.ml-5 iframe {
		width: 100%;
	}
	.osahan-payment .nav-link i {
		display: block;
		margin: 0 auto 10px auto;
	}
	.osahan-payment .nav-link {
		text-align: center;
	}
	.osahan-track-order-detail p.text-gray.mb-5 {
		margin: 1px 0 11px 0 !important;
	}
	.osahan-account-page-left .nav-tabs {
		padding-left: 0px !important;
	}
	.osahan-account-page-left {
		height: auto !important;
		margin: 0 0 15px 0;
	}
	.order-list .float-right {
		float: none !important;
	}
	.row.mb-4 {
		margin-bottom: 0px !important;
	}
	.app {
		margin-bottom: 26px;
	}
	.footer ul li a {
		font-size: 12px;
	}
	.footer h6 {
		font-size: 13px;
		text-transform: capitalize;
	}
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.container {
		max-width: 100%;
	}
	.osahan-nav {
		padding: 15px 15px;
	}
	.navbar-toggler {
		padding: 5px 8px;
		border-radius: 2px;
	}
	.osahan-nav .nav-link {
		margin: 0px;
		text-align: center;
		padding: 14px 0 !important;
		border-bottom: 1px solid #eef0ef;
	}
	.osahan-nav .dropdown-item {
		text-align: center;
	}
	.osahan-slider {
		padding: 18px 0 0 0px !important;
	}
	.pt-5,
	.py-5 {
		padding-top: 3rem !important;
	}
	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important;
	}
	.homepage-search-block {
		padding: 3rem 0 !important;
	}
	.card {
		margin-bottom: 15px !important;
	}
	p {
		font-size: 12px;
	}
	.restaurant-detailed-header-left img {
		float: none !important;
		margin: 0 0 14px 0 !important;
	}
	.restaurant-detailed-header-left {
		text-align: center;
	}
	.restaurant-detailed-header-right .btn-success {
		margin-top: 18px;
	}
	.restaurant-detailed-header-right.text-right {
		text-align: center !important;
	}
	.restaurant-detailed-action-btn.float-right {
		float: none !important;
		margin: auto;
		text-align: center;
		width: 100% !important;
		display: block;
		padding: 12px 0;
		border-bottom: 1px solid #dedede;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.offer-dedicated-nav .nav-item {
		margin: 0 8px !important;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.address-map.float-right.ml-5 {
		float: none !important;
		margin: 0 0 20px 0 !important;
	}
	.address-map.float-right.ml-5 iframe {
		width: 100%;
	}
	.osahan-payment .nav-link i {
		display: block;
		margin: 0 auto 10px auto;
	}
	.osahan-payment .nav-link {
		text-align: center;
	}
	.osahan-track-order-detail p.text-gray.mb-5 {
		margin: 1px 0 11px 0 !important;
	}
	.osahan-account-page-left .nav-tabs {
		padding-left: 0px !important;
	}
	.osahan-account-page-left {
		height: auto !important;
		margin: 0 0 15px 0;
	}
	.order-list .float-right {
		float: none !important;
	}
	.row.mb-4 {
		margin-bottom: 0px !important;
	}
	.app {
		margin-bottom: 26px;
	}
	.footer ul li a {
		font-size: 12px;
	}
	.footer h6 {
		font-size: 13px;
		text-transform: capitalize;
	}
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	.container {
		max-width: 100%;
	}
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.btn-type {
	background-color: #fff;
	border-color: transparent;
	color: #000;
}
.active-menu {
	background-color: red;
	width: 80%;
	height: 80%;
	border-radius: 10px;
}
.rounded-right-20 {
	border-radius: 0 10px 10px 0 !important;
}

.recommend {
	height: 100%;
	background-color: #ffffff;
}

.title-container {
	height: 111px;
	display: 'flex';
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.table {
	background-color: #fff;
	font-size: 20px;
	font-weight: bold;
	color: orange;
}

.Modal {
	position: fixed;
	/* TODO: 已选商品css动态设置高度， 保持checkout button在底部*/
	top: 50%;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px;
	background-color: #fff;
}

.Modal-tips {
	position: fixed;
	top: 70%;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px;
	background-color: #fff;
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
}
.tips-btn {
	background-color: transparent !important;
	border: 1px solid #000000 !important;
	color: #000000 !important;
	font-weight: 600;
	border-radius: 4px;
}

.tips-percent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent !important;
	border: 1px solid #000000 !important;
	color: #000000 !important;
	font-weight: 600;
	border-radius: 4px;
	box-shadow: none !important;
	transition: none !important;
}
.tips-percent-select {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	border-radius: 4px;
	color: #fff !important;
	background-color: #000000 !important;
	box-shadow: none !important;
	transition: none !important;
}


.modal-content  {
	background-color: 'transparent' !important;
}

.takeaway-button {
	height:50px;
	width:auto;
	background-color: #fff !important;
	color: #000;
	font-size: 20px;
	font-weight: 500;
	margin-top:  20px ;
	margin-bottom: 20px;
	border-radius: 30px !important;
}


.down-arrow {
	position: absolute;
	width: 25px;
	height: 25px;
	margin-left: 90px;
	margin-top: 10px;
}
.upper-layer {
	position: absolute;
	pointer-events:none;
	margin-left:3px;
	margin-top: 5px;
}

.dropdown-picker {
	position: relative;
}

.takeaway-button:focus {
	/* border: 1px solid #00bfff !important; */
	color: #000000 !important;
	outline: none !important;
	box-shadow: none !important;
}
.modal-100 {
	width:100vw;
	margin:0;
	height:100%;
}
.rule {
	background: none;
	font-size: 14px;
	/* padding: 1px ; */
	border: 1px solid #00BFFF;
	background: #00BFFF;
	color: #fff !important;
	font-weight: 400;
	border-radius: 3px;
	z-index: 10;
}

.details-modal {
	width: 100%;
	height: 100%;
	margin: 0;
}
.my-modal-content {
	box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
	height: auto;
	min-height: 100%;
	border-radius: 0;
}
.pic-area {
	position: relative;
}
.close-btn {
	position: absolute !important;
	color: gray !important;
}
.detail-down-arrow {
	/* margin-left: 50px; */
	margin-top:8px;
}

/* .pic-area {
	grid-area: header;
}

.name-area {
	height:30px;
	grid-area: subHeader;
}
.detail-content {
	display: grid;
	grid-template-areas: 'header '
	'subHeader'
	' main '
	'footer';
	height: 100%;
}
.rules-area {
	grid-area: main;
} */
.rules-area {
	overflow-y: scroll;
}

.add-to-cart{
	/* display: flex; */
	/* align-items: flex-end; */
	/* grid-area: footer; */
	position: block;
	width: 100%;
	height: 20%;
	box-sizing: border-box;
	/* margin-bottom: 100px; */
	z-index: 30;
	/* flex-direction: column; */
	justify-content: center;
	/* background-color: #00bfff; */
	
}

.checkout-modal {
	align-items: felx-end !important;
}



.select-btn {
	border: 1px solid #ebe9e9 !important;
	width: 100%;
	border-radius: 3px;
	background: white !important ;
	color: #9e9e9e !important;
	box-shadow: none !important;
}
.select-btn:focus {
	color:#00bfff !important;
	border: 1px solid #00bfff !important;
	outline: none !important;
	box-shadow: none !important;
}
.cart-btn {
	margin-top: 10px;
	width: 90%;
	height:50px;
	border: 1px solid #000;
	background-color:'#00BFFF' ;
	/* z-index: 50; */
}